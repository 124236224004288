.inv {
  margin: 1rem;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 17px;
  font-weight: 500;
}
.flex {
  display: flex;
}
.inv_main {
  border: 1px solid black;
  width: 100%;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.inv_main_1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.aid_details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.aid_name {
  font-size: 17px;
  font-weight: bold;
}
.aid_ad {
  display: flex;
  gap: 0.25rem;
}
.aid_ad > span {
  font-size: 15px;
  font-weight: bold;
}
.aid_ad > div {
  font-size: 15px;
}
.logo {
  height: 100%;
  display: flex;
  justify-content: center;
}

/* Tax Invoice New */
.invoice_main {
  margin-bottom: 3rem;
  padding: 1rem;
  color: #000 !important;
  font-size: 10px !important;
}
.inv_main_table {
  padding: 10px;
  color: #000 !important;
}
.td_1 {
  max-width: 350px;
  color: #000 !important;
  font-size: 10px !important;
  border: 1px solid black;
  padding: 2px;
}
.td_1_data {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.td_1_data > div {
  text-align: left;
}
.td_2 {
  padding: 2px;
  min-width: 100px;
  height: 100%;
  /* width: 100%; */
  font-size: 10px !important;
  border: 1px solid black;
}
.td_2_data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.norm_text {
  /* font-size: ; */
  color: #000 !important;
}
.bold_text {
  font-weight: bold;
  color: #000 !important;
}

/* E Invoice Css */
.env_main {
  font-size: 14px;
  color: #000;
  width: 100%;
}
.out_side {
  display: flex;
  width: 100%;
}
.out_side > div {
  flex: 1;
  text-align: left;
}
.main_div_container {
  display: flex;
  width: calc(100% - 2rem);
  flex-direction: column;
  gap: .5rem;
  margin: 1rem;
  border: 1px solid black;
  padding: .23rem 2rem;
}
.env_1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.env_1 > div {
  flex: 1;
}
.env_1_data_2 {
  display: flex;
  flex-direction: column;
  gap: .23rem;
  font-size: 11px;
  /* padding: 1rem 2rem; */
}
.env_1_data_3{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.env_2{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
}
.env_2>div{
  width: 100%;
  padding: 3px 2px;
  border: 1px solid black;
}
.env_2_2{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.env_2_2_2{
display: flex;
flex-direction: row;
}
.env_2_2_2 > div{
  flex: 1;
}
.env_th_td{
  background-color: #fff;
  border: 1px solid black !important;
  color: #000;
}
.env_6_1{
  display: flex;
  gap: 12px;

}
.env_6_1_2{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.env_6_1_2_1{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.env_6_1_2_1 > table > tr> th{
  /* display: flex;
  flex: 1; */
  background-color: #fff;
  color: #000;
  border-color: #000;
}
.env_6_1_2_1 > table > tr> td{
  /* display: flex;
  flex: 1; */
  background-color: #fff;
  color: #000;
  /* border-color: #000; */
}
.env_6_1_2_1 > table > tr{
border: 1px solid black;
}
