.GST-btn {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  min-height: 35px;
  border: 0px;
  text-align: center;
  cursor: pointer;
  color: white;
  padding: 0px 12px;
  font-size: 0.8rem;
  border-color: #0797ad !important;
  background-color: #08a2b9 !important;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: flex;
  align-items: center;
  gap: 5px;
}
.GSTR1Mian {
  display: flex;
  gap: 10px;
}
.tb1 {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 600px) {
 .GSTR1Mian {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
}