.layout-main {
  width: 100vw;
  height: 100vh;
  /* background-color: red; */
  display: flex;
  position: fixed;
  /* overflow: hidden; */
}

.layout-sidebar {
  background-color: #f5f6fa;
  border-right: 1px solid #e5e9f2;
  /* overflow-x: scroll; */
  height: 100%;
}

.layout-body {
  height: 100%;
  background-color: #fff;
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
}

.layout-header {
  height: 4.5rem;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e5e9f2;
  display: flex;
}

.layout-outlet {
  background-color: #fff;
  width: 100%;
  overflow-x: scroll !important;
}


.layout-outlet2 {
  background-color: #fff;
  width: calc(100% - 15rem - 40px);
  overflow-x: scroll !important;
}
.layout-outlet::-webkit-scrollbar {
  display: none;
}
.sidebar-new{
  display: none;
}
.ant-drawer-header-title{
  display: flex !important;
  justify-content: flex-end !important;
}
.ant-drawer-body{
  padding: 0 !important;
}
.ant-drawer-header{
  padding: 10px 0px !important;
  font-size: 20px !important;
  border-bottom: none !important;
}
.ant-drawer-body .ant-drawer-header{
  background-color: #dbdbdb !important;
}



@media (max-width: 906px) {
  .layout-sidebar {
    display: none;
  }
  .sidebar-new{
    display: block;
  }

  .layout-outlet2 {
    width: calc(100%);
  }
}

@media screen and (max-width:500px) {
  .layout-header{
    height: auto;
  }
  .layout-outlet{
    margin-bottom: 3.5rem;

  }

  
}
